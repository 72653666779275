import { BehaviorSubject, Observable } from 'rxjs';

export interface State<T> {
  loading: boolean;
  data: T | null;
  error: string | null;
}

export class StateService<T> {
  private stateSubject = new BehaviorSubject<State<T>>({
    loading: false,
    data: null,
    error: null,
  });

  // Observable for components to subscribe to
  state$: Observable<State<T>> = this.stateSubject.asObservable();

  // Get the current state value
  getState(): State<T> {
    return this.stateSubject.getValue();
  }

  // Update the state
  setState(newState: State<T>): void {
    this.stateSubject.next(newState);
  }

  // Load data into the state
  loadData(fetchData: () => Observable<T>): void {
    this.setState({ ...this.getState(), loading: true });
    fetchData().subscribe({
      next: (data) => {
        this.setState({ loading: false, data, error: null });
      },
      error: (err) => {
        this.setState({ loading: false, data: null, error: err.message || 'Error' });
      },
    });
  }
}
