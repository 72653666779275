import {Pipe, PipeTransform} from '@angular/core';
import {HelperService} from '../services/helper.service';

@Pipe({
  name: 'protectionClassName'
})
export class ProtectionClassNamePipe implements PipeTransform {

  transform(id: number): string {
    const data = HelperService.protectionClassList.find(item => item.id === id);
    return data ? data.name : '';
  }
}
