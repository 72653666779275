import {Component, Input, OnInit} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ElectroDeviceInventoryService} from '../../services/electro-device-inventory.service';

@Component({
  selector: 'app-import-documentation',
  templateUrl: './import-documentation.component.html',
  styleUrls: ['./import-documentation.component.css']
})
export class ImportDocumentationComponent implements OnInit {

  @Input() customerNumber: number;
  @Input() buildingNumber: number;
  @Input() buildingId: number;
  @Input() barcode: number;

  pdfImporter = {
    fileNames: Array<string>()
  };

  importing = false;
  importedSuccessfully = false;

  optionPdfInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Application_Pdf],
    disableMultipart: false
  };

  totalUploading = 0;

  constructor(public pdfUploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private electroDeviceInventoryService: ElectroDeviceInventoryService) {
  }

  upload = {
    customerNumber: 0,
    buildingNumber: 0,
    barcode: null,
    documentDate: '',
    isTypeInvoice: false,
    isTypeService: false,
    isTypeDocument: false,
    isTypeMaintenance: false,
    isTypeContract: false,
    isTypeManual: false,
    isTypeRepair: false
  };

  ngOnInit() {

    this.pdfImporter.fileNames = new Array<string>();

    this.pdfUploader.queue = [];
    this.pdfUploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.pdfUploader.onDropError$.subscribe(
      (err) => {
      });

    this.pdfUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.pdfUploader.onSuccess$.subscribe(
      (data: any) => {
        this.totalUploading = 0;
        this.importedSuccessfully = true;
        this.importing = false;
      }
    );
  }

  uploadPdfFiles(item: FileItem) {

    this.importing = true;
    this.upload.customerNumber = this.customerNumber;
    this.upload.buildingNumber = this.buildingNumber;
    this.upload.barcode = this.barcode;

    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadDocumentationUrl(this.buildingId, this.upload)
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.pdfUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  removePDFDocuments() {
    this.pdfImporter.fileNames = new Array<string>();
    this.pdfUploader.queue = [];
  }

  uploadAll() {
    const that = this;
    this.importing = true;
    this.totalUploading = this.pdfUploader.queue.length;
    this.pdfUploader.queue.forEach(function (value) {
      that.uploadPdfFiles(value);
    });

    this.upload = {
      customerNumber: 0,
      buildingNumber: 0,
      barcode: null,
      documentDate: '',
      isTypeInvoice: false,
      isTypeService: false,
      isTypeDocument: false,
      isTypeMaintenance: false,
      isTypeContract: false,
      isTypeManual: false,
      isTypeRepair: false
    };

    this.totalUploading = 0;
  }

  canImport() {
    return !this.importing && this.barcode && this.upload.documentDate.length > 0 && this.isTypeSelected() && this.pdfUploader.queue.length > 0;
  }

  isTypeSelected() {
    return this.upload.isTypeInvoice || this.upload.isTypeService || this.upload.isTypeDocument || this.upload.isTypeMaintenance || this.upload.isTypeContract || this.upload.isTypeManual || this.upload.isTypeRepair;
  }

}
