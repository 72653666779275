import {Injectable} from '@angular/core';
import {environment} from '../../environments';

@Injectable({
  providedIn: 'root'
})
export class HelperService {


  constructor() {
  }

  static testNormTypeMap: { [key: number]: string } = {
    1: 'DIN EN 50699 (VDE 0702)',
    2: 'DIN EN 62353 (VDE 0751-1)',
    3: 'DIN VDE 105-100',
    4: 'DIN EN 50678 (VDE 0701)',
    5: 'ÖVE/ÖNORM E 8701-1',
  };

  static testTypeMap: { [key: number]: string } = {
    1: 'Elektrische Geräte und Betriebsmittel',
    2: 'Medizinische (elektrische) Geräte',
    3: 'Elektrische Anlagen',
  };

  static controlTypeList = [
    {id: 0, name: 'elektr. 230V', default: true},
    {id: 1, name: 'elektr. 400V', default: false}
  ];

  static yesNoList = [
    {id: 0, name: 'Ja', value: true},
    {id: 1, name: 'Nein', value: false},
  ];

  static nextTestingDateList = [
    {id: 0, name: 'Keine Auswahl', value: false},
    {id: 1, name: '3 Monate', value: false},
    {id: 2, name: '6 Monate', value: false},
    {id: 3, name: '1 Jahr', value: true},
    {id: 4, name: '2 Jahre', value: false},
    {id: 5, name: '3 Jahre', value: false},
    {id: 6, name: '4 Jahre', value: false},
    {id: 7, name: '5 Jahre', value: false}
  ];

  static protectionClassList = [
    {id: 0, name: 'I'},
    {id: 1, name: 'II'},
    {id: 2, name: 'III'},
  ];

  static testingTypeList = [
    {id: 1, name: 'Ja'},
    {id: 0, name: 'Nein'},
    {id: 2, name: 'Fehlend'},
  ];

  static propertyList = [
    {id: 0, name: 'Einrichtung', default: false},
    {id: 1, name: 'Bewohner', default: false},
    {id: 2, name: 'Unternehmen', default: true}
  ];

  static getAPIUrl(s?: string) {
    return s ? `${environment.apis.backend}/${s.replace(/^\/+/, '')}` : environment.apis.backend;
  }

  static formatBarcode(barcode: any) {
    return ('000000' + barcode).slice(-6);
  }


  static padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  static isDate(value: any) {
    return value ? !isNaN((new Date(value)).getTime()) : false;
  }
}
