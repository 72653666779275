import {ElectroDeviceType} from './electro-device-type';
import {ElectroDeviceManufacturer} from './electro-device-manufacturer';
import {ElectroDeviceInventory} from './electro-device-inventory';

export class ElectroDeviceInventoryItem {
  id: number;
  vedin?: string;
  barcode?: number;
  protectionClass?: number;
  deviceType?: ElectroDeviceType;
  deviceManufacturer?: ElectroDeviceManufacturer;
  deviceModel?: string;
  serialNumber?: string;
  location?: string;
  comment?: string;
  isDGUVV3Passed?: boolean;
  nextDGUVV3?: string;
  images: [];
  property?: string;
  installation?: string;
  electroDeviceInventory?: ElectroDeviceInventory;
  testingDeviceId?: number;
  registerNumber?: number;
  nextTestingDateType: boolean;
  testStatus?: number;
}
