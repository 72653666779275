import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CompanyService} from '../../../services/company/company.service';
import {BuildingService} from '../../../services/building/building.service';
import {of} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {ElectroDeviceTesting} from '../../../models/electro-device-testing';
import {DataResponse} from '../../../models/data-response';
import {ElectroDeviceInventory} from '../../../models/electro-device-inventory';
import {ToastrService} from 'ngx-toastr';
import {ElectroDeviceTestingService} from '../../../services/electro-device-testing.service';
import {HelperService} from '../../../services/helper.service';
import {Building} from '../../../models/building';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-testing-create',
  templateUrl: './testing-create.component.html',
  styleUrls: ['./testing-create.component.css']
})
export class TestingCreateComponent implements OnInit {
  @Output() done = new EventEmitter<boolean>(); // Emit when action is complete

  testingCreating = false;
  newElectroDeviceTesting = new ElectroDeviceTesting();

  testNormTypeList = Object.entries(HelperService.testNormTypeMap).map(([id, name]) => ({
    id: Number(id),
    name,
    value: id === '1',
  }));

  testTypeList = Object.entries(HelperService.testTypeMap).map(([id, name]) => ({
    id: Number(id),
    name,
    value: id === '1',
  }));

  companyListLoading = false;
  companyList = [];
  companySelectedId = null;

  buildingListLoading = false;
  buildingCacheList = [];
  buildingList = [];
  buildingSelectedId = null;

  constructor(
    private companyService: CompanyService,
    private buildingService: BuildingService,
    private electroDeviceTestingService: ElectroDeviceTestingService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.loadCompanies();
    this.loadBuildings();
  }

  loadCompanies(): void {
    this.companyListLoading = true;

    this.companyService.getNameList().subscribe(
      (response) => {
        this.companyList = response.data;
        this.companyListLoading = false;
      },
      (error) => {
        this.toastr.error('Die Firmen können nicht geladen werden!');
        this.companyListLoading = false;
      }
    );
  }

  loadBuildings(): void {
    this.buildingListLoading = true;

    this.buildingService.getNameList().subscribe(
      (response) => {
        this.buildingList = response.data;
        this.buildingListLoading = false;
      },
      (error) => {
        this.toastr.error('Die Gebäude können nicht geladen werden!');
        this.buildingListLoading = false;
      }
    );
  }

  createTesting(): void {
    this.testingCreating = true;
    this.electroDeviceTestingService.create(this.newElectroDeviceTesting)
      .pipe(
        tap((dataResponse: DataResponse<ElectroDeviceInventory>) => {
          if (dataResponse.status === 'success') {
            this.toastr.success('Prüfung erfolgreich erstellt.');
            this.done.emit(true); // Notify parent of success
          } else {
            this.toastr.error(dataResponse.message);
            this.done.emit(false); // Notify parent of failure
          }
        }),
        catchError((error) => {
          this.toastr.error('Fehler beim Erstellen: ' + error.message);
          this.done.emit(false); // Notify parent of failure
          return of(); // Gracefully handle the error
        }),
        finalize(() => {
          this.testingCreating = false;
        })
      )
      .subscribe();
  }

  cancel(): void {
    this.done.emit(false); // Notify parent of cancellation
    Swal.close();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onSearchCompanyChange($event: any) {
    this.companySelectedId = $event;

    // Reset everytime we choose a company
    this.newElectroDeviceTesting.buildingId = null;

    this.buildingCacheList = this.buildingList.filter((building: Building) => {
      return building.company.id === $event;
    });
  }

  onSearchBuildingChange($event: any) {
    this.buildingSelectedId = $event;
  }
}
