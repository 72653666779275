import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
  AppComponent,
  DashboardComponent,
  ListUserComponent,
  AddUserComponent,
  EditUserComponent,
  ViewUserComponent,
  UserComponent
} from './components';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AppRoutingModule} from './app-routing.module';
import {initializer} from './utils/app-init';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {DataTablesModule} from 'angular-datatables';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {BreadcrumbModule} from 'angular-crumbs';
import {NgxUploadModule, MineTypeEnum, DropTargetOptions} from '@wkoza/ngx-upload';
import {ErrorComponent} from './components/error/error.component';
import {DebounceClickDirective} from './directives/debounce-click.directive';
import {MeComponent} from './components/me/me.component';
import {TreeviewModule} from 'ngx-treeview';
import {ListActivitiesComponent} from './components/activities/list/list-activities.component';
import {ListLogsComponent} from './components/logs/list/list-logs.component';
import {DateAgoPipe} from './pipes/date-ago.pipe';

import {ManagementComponent} from './components/management/management.component';
import {ListCountryComponent} from './components/management/tab/list-country/list-country.component';
import {ListUserTypeComponent} from './components/management/tab/list-user-type/list-user-type.component';
import {ListCurrencyComponent} from './components/management/tab/list-currency/list-currency.component';
import {ListTimeZoneComponent} from './components/management/tab/list-time-zone/list-time-zone.component';
import {
  ElectroDeviceInventoryComponent
} from './components/building/view/tabs/electro-device-inventory/list/electro-device-inventory.component';
import {
  ViewElectroDeviceInventoryComponent
} from './components/building/view/tabs/electro-device-inventory/view/view-electro-device-inventory.component';
import {DatabaseComponent} from './components/database/database.component';
import {ListBuildingComponent} from './components/building/list/list-building.component';
import {AddBuildingComponent} from './components/building/add/add-building.component';
import {EditBuildingComponent} from './components/building/edit/edit-building.component';
import {ViewBuildingComponent} from './components/building/view/view-building.component';
import {ElectroDeviceComponent} from './components/electro-device/electro-device.component';
import {ListElectroDeviceComponent} from './components/electro-device/list/list-electro-device.component';
import {AddElectroDeviceComponent} from './components/electro-device/add/add-electro-device.component';
import {EditElectroDeviceComponent} from './components/electro-device/edit/edit-electro-device.component';
import {ViewElectroDeviceComponent} from './components/electro-device/view/view-electro-device.component';
import {ListElectroDeviceTypeComponent} from './components/management/tab/list-electro-device-type/list-electro-device-type.component';
import {
  ListElectroDeviceManufacturerComponent
} from './components/management/tab/list-electro-device-manufacturer/list-electro-device-manufacturer.component';
import {CompanyComponent} from './components/company/company.component';
import {AddCompanyComponent} from './components/company/add/add-company.component';
import {EditCompanyComponent} from './components/company/edit/edit-company.component';
import {ListCompanyComponent} from './components/company/list/list-company.component';
import {ReportComponent} from './components/report/report.component';
import {ListReportComponent} from './components/report/list/list-report.component';
import {ViewTabletComponent} from './components/tablet/view/view-tablet.component';
import {UploadPdfComponent} from './components/building/view/tabs/electro-device-inventory/list/upload-pdf/upload-pdf.component';
import {UploadImageComponent} from './components/upload-image/upload-image.component';
import {NgxGalleryModule} from 'ngx-gallery';
import {SummaryPdfComponent} from './components/summary-pdf/summary-pdf.component';
import {TutorialComponent} from './components/tutorial/tutorial.component';
import {BuildingComponent} from './components/building/building.component';
import {InventorySummaryPdfComponent} from './components/inventory-summary-pdf/inventory-summary-pdf.component';
import {InventoryComponent} from './components/inventory/inventory.component';
import {InventoryPdfComponent} from './components/inventory-pdf/inventory-pdf.component';
import {DefectSummaryPdfComponent} from './components/defect-summary-pdf/defect-summary-pdf.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { ScannerSearchComponent } from './components/scanner-search/scanner-search.component';
import { ImportDocumentationComponent } from './components/import-documentation/import-documentation.component';
import { ElectroDeviceDocumentPdfComponent } from './components/electro-device-document-pdf/electro-device-document-pdf.component';
import { ListTestingDeviceComponent } from './components/management/tab/list-testing-device/list-testing-device.component';
import { ImportElectroDeviceTestingComponent } from './components/import-electro-device-testing/import-electro-device-testing.component';
import { BuildingCommentListComponent } from './components/building-comment-list/building-comment-list.component';
import {NextDateTypePipe} from './pipes/next-date-type.pipe';
import { InputFocusDirective } from './directives/input-focus.directive';
import { InputMaxLengthDirective } from './directives/input-max-length.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { TestingCreateComponent } from './components/testing/create/testing-create.component';
import {TestingComponent} from './components/testing/testing.component';
import { TestingItemCreateSingleComponent } from './components/testing/testing-item/create-single/testing-item-create-single.component';
import { TestingItemCreateMultipleComponent } from './components/testing/testing-item/create-multiple/testing-item-create-multiple.component';
import { TestingItemEditComponent } from './components/testing/testing-item/edit/testing-item-edit.component';
import {TestingItemListComponent} from './components/testing/testing-item/list/testing-item-list.component';
import { ProtectionClassNamePipe } from './pipes/protection-class-name.pipe';
import { PropertyNamePipe } from './pipes/property-name.pipe';
import { NextTestingDateTypeNamePipe } from './pipes/next-testing-date-type-name.pipe';
import { ControlTypeNamePipe } from './pipes/control-type-name.pipe';

export const ngxDropTargetOptions: DropTargetOptions = {
  color: 'dropZoneColor',
  colorDrag: 'dropZoneColorDrag',
  colorDrop: 'dropZoneColorDrop',
  multiple: true,
  accept: [MineTypeEnum.Image, MineTypeEnum.Image_Jpeg]
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ListUserComponent,
    AddUserComponent,
    EditUserComponent,
    ViewUserComponent,
    UserComponent,
    ErrorComponent,
    DebounceClickDirective,
    MeComponent,
    ListActivitiesComponent,
    ListLogsComponent,
    DateAgoPipe,
    NextDateTypePipe,
    ManagementComponent,
    ListCountryComponent,
    ListUserTypeComponent,
    ListCurrencyComponent,
    ListTimeZoneComponent,
    ListCurrencyComponent,
    ListUserTypeComponent,
    ElectroDeviceInventoryComponent,
    ViewElectroDeviceInventoryComponent,
    DatabaseComponent,
    ListBuildingComponent,
    AddBuildingComponent,
    EditBuildingComponent,
    ViewBuildingComponent,
    ElectroDeviceComponent,
    ListElectroDeviceComponent,
    AddElectroDeviceComponent,
    EditElectroDeviceComponent,
    ViewElectroDeviceComponent,
    ListElectroDeviceTypeComponent,
    ListElectroDeviceManufacturerComponent,
    CompanyComponent,
    AddCompanyComponent,
    EditCompanyComponent,
    ListCompanyComponent,
    ReportComponent,
    ListReportComponent,
    ViewTabletComponent,
    UploadPdfComponent,
    UploadImageComponent,
    SummaryPdfComponent,
    TutorialComponent,
    BuildingComponent,
    InventorySummaryPdfComponent,
    InventoryComponent,
    InventoryPdfComponent,
    InventoryPdfComponent,
    DefectSummaryPdfComponent,
    ScannerComponent,
    ScannerSearchComponent,
    ImportDocumentationComponent,
    ElectroDeviceDocumentPdfComponent,
    ListTestingDeviceComponent,
    ImportElectroDeviceTestingComponent,
    BuildingCommentListComponent,
    InputFocusDirective,
    InputMaxLengthDirective,
    TestingComponent,
    LoadingSpinnerComponent,
    TestingCreateComponent,
    TestingItemCreateSingleComponent,
    TestingItemCreateMultipleComponent,
    TestingItemListComponent,
    TestingItemEditComponent,
    ProtectionClassNamePipe,
    PropertyNamePipe,
    NextTestingDateTypeNamePipe,
    ControlTypeNamePipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DataTablesModule,
    NgSelectModule,
    FormsModule,
    NgxDatatableModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary mr-3',
      cancelButtonClass: 'btn btn-dark'
    }),
    BreadcrumbModule,
    NgxUploadModule.forRoot(ngxDropTargetOptions),
    TreeviewModule.forRoot(),
    NgxGalleryModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
