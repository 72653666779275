import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClientUploadService} from '@wkoza/ngx-upload';
import {Observable} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {SwalComponent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {State, StateService} from '../../services/state.service';
import {ElectroDeviceTestingService} from '../../services/electro-device-testing.service';
import {AuthenticationService} from '../../services/authentication.service';
import {HelperService} from '../../services/helper.service';
import {ElectroDeviceTesting} from '../../models/electro-device-testing';

import {ToastrService} from 'ngx-toastr';
import {DataResponse} from '../../models/data-response';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css'],
  providers: [StateService]
})
export class TestingComponent implements OnInit {

  @ViewChild('createTestingModal', {static: false}) createTestingModal!: SwalComponent;

  testingState$: Observable<State<any>>;
  selectedTesting: ElectroDeviceTesting = null;
  testingSelectedToDelete = null;

  createTestingModalOptions = {
    title: 'Neue Prüfung',
    width: '50%',
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false
  };

  createTestingItemMultipleModalOptions = {
    title: 'Wiederholungsprüfung (Liste)',
    width: '100%',
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false
  };

  createTestingItemSingleModalOptions = {
    title: 'Wiederholungsprüfung (Detail)',
    width: '60%',
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false
  };

  testStatusTypeMap: { [key: number]: { label: string; badgeClass: string } } = {
    0: {label: 'Offen', badgeClass: 'badge-warning'},
    10: {label: 'In Bearbeitung', badgeClass: 'badge-dark'},
    20: {label: 'Abgebrochen', badgeClass: 'badge-danger'},
    30: {label: 'Wartet auf Freigabe', badgeClass: 'badge-info'},
    100: {label: 'Prüfung Abgeschlossen', badgeClass: 'badge-success'}
  };

  constructor(public fileUploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private electroDeviceTestingService: ElectroDeviceTestingService,
              private stateService: StateService<any>) {

    this.testingState$ = this.stateService.state$;
  }

  ngOnInit() {
    this.loadTestings();
  }

  /**
   * This method returns the testing norm type
   *
   * @param testing
   */
  getTestingTestNormType(testing: ElectroDeviceTesting): string {
    return HelperService.testNormTypeMap[testing.testNormType] || 'Unbekannt';
  }

  /**
   * This method returns the testing type
   *
   * @param testing
   */
  getTestingTestType(testing: ElectroDeviceTesting): string {
    return HelperService.testTypeMap[testing.testType] || 'Unbekannt';
  }

  /**
   * This method checks, if the user has the required permissions
   *
   * @param role
   * @param roleType
   * @param rolePermissionType
   */
  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  /**
   * This method loads the testings from the database.
   */
  loadTestings(): void {
    this.stateService.loadData(() => this.electroDeviceTestingService.getList().pipe(
      map((response) => response.data)
    ));
  }

  /**
   * This method calls the padLeft helper do add padding from left.
   *
   * @param text
   * @param padChar
   * @param size
   */
  padLeft(text: string, padChar: string, size: number): string {
    return HelperService.padLeft(text, padChar, size);
  }

  /**
   * This method returns the testing status text by the given status state
   *
   * @param testing
   */
  getTestingStatus(testing: ElectroDeviceTesting): { label: string; badgeClass: string } {
    return this.testStatusTypeMap[testing.status] || {label: 'Unbekannt', badgeClass: 'badge-secondary'};
  }

  handleCreateTestingResponse(result: boolean): void {
    if (result) {
      this.loadTestings(); // Reload data on success
    }
  }

  deleteTesting(): void {
    if (!this.testingSelectedToDelete.id) {
      this.toastr.error('No device selected to delete.');
      return;
    }

    this.electroDeviceTestingService
      .delete(this.testingSelectedToDelete.id)
      .pipe(
        tap((resp) => {
          if (resp.status === 'success') {
            this.toastr.success('Prüfung wurde erfolgreich gelöscht.');
            this.removeTesting(this.testingSelectedToDelete.id); // Remove the item from the state
          } else {
            this.toastr.error(resp.message);
          }
        }),
        catchError((error) => {
          this.toastr.error('Prüfung kann nicht gelöscht werden. Grund: ' + error.message);
          return []; // Return an empty observable to avoid breaking the stream
        }),
        finalize(() => {
        })
      )
      .subscribe();
  }

  updateTestingStatus(status: number, testing: ElectroDeviceTesting): void {
    this.electroDeviceTestingService
      .setStatus(status, testing)
      .pipe(
        tap((dataResponse: DataResponse<ElectroDeviceTesting>) => {
          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          } else if (dataResponse.status === 'success') {
            this.updateTesting({...dataResponse.data}); // Update the local state
          }
        }),
        catchError((error) => {
          this.toastr.error('Prüfungsstatus kann nicht geändert werden. Grund: ' + error.message);
          return []; // Return an empty observable to avoid breaking the stream
        }),
        finalize(() => {
        })
      )
      .subscribe();
  }

  private removeTesting(testingId: number): void {
    // Get the current state
    const currentState = this.stateService.getState();

    // Filter out the deleted testing
    const updatedData = currentState.data.filter((item: ElectroDeviceTesting) => item.id !== testingId);

    // Update the state with the modified data
    this.stateService.setState({
      ...currentState,
      data: updatedData,
    });
  }

  private updateTesting(testing: ElectroDeviceTesting) {

    const currentState = this.stateService.getState();

    // Update the testing with the same ID in the current data
    const updatedData = currentState.data.map((item: ElectroDeviceTesting) =>
      item.id === testing.id ? {...item, ...testing} : item
    );

    // Update the state with the modified data
    this.stateService.setState({
      ...currentState,
      data: updatedData,
    });
  }

  handleCreateTestingItemMultipleResponse(result: boolean) {
    if (result) {
      this.loadTestings();
    }
  }

  handleCreateTestingItemSingleResponse(result: boolean) {
    if (result) {
      this.loadTestings();
    }
  }

  prepareModal(testing: ElectroDeviceTesting) {
    this.selectedTesting = testing;
  }

  checkTesting(testing: ElectroDeviceTesting) {
    this.electroDeviceTestingService.checkTesting(testing).subscribe((dataResponse: DataResponse<ElectroDeviceTesting>) => {
        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }
        if (dataResponse.status === 'success') {
          this.loadTestings();
        }
      },
      error => {
        this.toastr.error(error.toString());
      });
  }
}
