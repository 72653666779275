import {ElectroDeviceTesting} from './electro-device-testing';

export class ElectroDeviceTestingItem {
  id: number;
  vedin?: string;
  barcode?: number;
  nextTestingDateType = 3;
  comment?: string;
  registerNumber?: number;
  createdAt?: string;
  updatedAt?: string;
  location?: string;
  images: any[] = [];
  protectionClass?: number;
  deviceType?: string;
  deviceManufacturer?: string;
  nextDGUVV3?: string;
  deviceModel?: string;
  serialNumber?: string;
  installation?: string;
  testingDeviceId?: number;
  isCESymbolExist?: boolean;
  controlType?: number;
  property?: number; // 0, 1 or 2 currently
  testStatus?: number;
  electroDeviceTesting?: ElectroDeviceTesting;
  testingSending = false;
  parentItemId: number;
  isDuplicate: false;
}
