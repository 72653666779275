import {ElectroDeviceTestingItem} from './electro-device-testing-item';

export class ElectroDeviceTesting {
  id: number;
  buildingId?: number;
  buildingInternalNumber?: number;
  buildingName?: string;
  companyCustomerNumber?: string;
  companyId?: number;
  companyName?: string;
  createdAt?: string;
  updatedAt?: string;
  testNormType?: number;
  testType?: number;
  testResultDate?: string;
  status?: number;
  totalItems?: number;
  totalTestedOk?: number;
  totalTestedNotOk?: number;
  totalAllItems?: number;
  totalTestableItems?: number;
  testedItems: ElectroDeviceTestingItem[];
  totalMissingDevices?: number;
}
