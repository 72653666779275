import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ElectroDeviceTesting} from '../../../../models/electro-device-testing';
import {HelperService} from '../../../../services/helper.service';
import {ElectroDeviceTestingItemService} from '../../../../services/electro-device-testing-item.service';
import {Page} from '../../../../models/page';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {ElectroDeviceTestingDeviceService} from '../../../../services/electro-device-testing-device.service';
import {ElectroDeviceTestingService} from '../../../../services/electro-device-testing.service';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {ElectroDeviceTestingItem} from '../../../../models/electro-device-testing-item';
import {combineLatest, Subject} from 'rxjs';
import {DataResponse} from '../../../../models/data-response';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-testing-item-create-multiple',
  templateUrl: './testing-item-create-multiple.component.html',
  styleUrls: ['./testing-item-create-multiple.component.css']
})
export class TestingItemCreateMultipleComponent implements OnInit {

  @Input() testing: ElectroDeviceTesting;
  @Output() done = new EventEmitter<boolean>();

  private barcodeSubject: Subject<string> = new Subject<string>();

  isDeviceListView = true;
  locationCountList = [];

  locationDeviceCountLoading = false;
  loadingLastTestedItem = false;
  lastRegisterNumber?: number = null;
  lastTestedItem: ElectroDeviceTestingItem = null;
  testingItems: ElectroDeviceTestingItem[] = [];
  testingItemsLoading = false;
  testingDevices = [];
  selectedTestingDeviceId = null;
  selectedTestingItem = null;
  protectionClassList = HelperService.protectionClassList;
  nextTestingDateList = HelperService.nextTestingDateList;
  locations = [];

  imageQueue: any[] = [];

  selectedLocation = null;
  barcodeFilter = '';
  filteredRows: ElectroDeviceTestingItem[] = [];

  sortOptions = [
    // {label: 'Standort (Aufsteigend)', value: 'location_asc'},
    // {label: 'Standort (Absteigend)', value: 'location_desc'},
    {label: 'Barcode (Aufsteigend)', value: 'barcode_asc'},
    {label: 'Barcode (Absteigend)', value: 'barcode_desc'}
  ];

  selectedSortOption = 'barcode_asc';
  registerNumberRequired = false;

  imageOptionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png, MineTypeEnum.Image_Jpeg],
    disableMultipart: false
  };

  sortColumn = ''; // Active column for sorting
  sortDirection = 'asc'; // Sorting direction: 'asc' or 'desc'


  constructor(public httpClientUploadService: HttpClientUploadService,
              private electroDeviceTestingService: ElectroDeviceTestingService,
              private electroDeviceTestingItemService: ElectroDeviceTestingItemService,
              private electroDeviceTestingDeviceService: ElectroDeviceTestingDeviceService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.loadTestingItems();
    this.loadTestingDeviceList();

    this.barcodeSubject.pipe(debounceTime(300)).subscribe(value => {
      this.onBarcodeChange(); // Call your method with the final value
    });

    this.httpClientUploadService.onAddToQueue$.subscribe((fileItem: FileItem) => {

      if (!this.selectedTestingItem) {
        return;
      }

      if (!this.imageQueue[this.selectedTestingItem.id]) {
        this.imageQueue[this.selectedTestingItem.id] = [];
      }

      this.imageQueue[this.selectedTestingItem.id].push(fileItem);
    });
  }

  populateLocationFilter() {

    this.locations = [];

    const uniqueLocations = new Set(this.testingItems.map(item => item.location));
    uniqueLocations.forEach(location => {
      this.locations.push({id: location, name: location});
    });

    /*
    if (this.locations && this.locations.length > 0) {
      this.selectedLocation = this.locations[0].name; // Set initial selection

    }

     */
  }

  onLocationChange(selectedItem: any) {
    this.filterRows();
  }

  onBarcodeChange() {
    requestAnimationFrame(() => {
      if (this.barcodeFilter) {

        const barcodeFilterValue = parseInt(this.barcodeFilter, 10);

        this.filteredRows = [...this.testingItems].filter(item => {
          const barcode = parseInt(item.barcode.toString(), 10);
          return barcode >= barcodeFilterValue && barcode <= barcodeFilterValue + 2;
        });
        this.sort();
      } else {
        this.filterRows();
      }
    });
  }

  onBarcodeKeyup(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    this.barcodeSubject.next(target.value); // Emit the new value
  }

  onSortChange() {
    requestAnimationFrame(() => {
      this.sort();
    });
  }

  sort() {
    /*
    if (this.selectedSortOption === 'location_asc') {
      this.filteredRows.sort((a, b) => (a.location || '').localeCompare(b.location || ''));
    } else if (this.selectedSortOption === 'location_desc') {
      this.filteredRows.sort((a, b) => (b.location || '').localeCompare(a.location || ''));
    } else
     */
    if (this.selectedSortOption === 'barcode_asc') {
      this.filteredRows
        .sort((a, b) => (a.barcode.toString() || '')
          .localeCompare(b.barcode.toString() || ''));
    } else if (this.selectedSortOption === 'barcode_desc') {
      this.filteredRows
        .sort((a, b) => (b.barcode.toString() || '')
          .localeCompare(a.barcode.toString() || ''));
    }
  }

  filterRows() {

    if (this.testingItems.length === 0) {
      this.toastr.error('Es wurde kein Gerät gefunden');
      return;
    }

    this.filteredRows = [...this.testingItems];
    this.barcodeFilter = null;
    this.filteredRows = this.filteredRows.filter(
      item => item.location === this.selectedLocation
    );
    this.sort();
  }

  loadTestingItems() {

    this.testingItemsLoading = true;

    this.electroDeviceTestingService.getItemList(this.testing).subscribe(response => {
      if (response.status === 'success') {

        const statusLabelCache = new Map<number, string>();

        this.testingItems = response.data.map((item: ElectroDeviceTestingItem) => {
          const testStatusTest = statusLabelCache.has(item.testStatus)
            ? statusLabelCache.get(item.testStatus)
            : this.getTestStatusLabel(item.testStatus);

          statusLabelCache.set(item.testStatus, testStatusTest);

          return {
            ...item,
            isCESymbolExist: item.isCESymbolExist ? item.isCESymbolExist : true,
            controlType: item.controlType ? item.controlType : 0,
            property: item.property ? item.property : 2,
            nextTestingDateType: item.nextTestingDateType ? item.nextTestingDateType : 3,
            images: [],
            testStatusTest,
            isDuplicate: false,
            testingSending: false
          };

        });

        this.filteredRows = [...this.testingItems];
        this.populateLocationFilter();
        this.filterRows();
        this.locationDeviceCountLoading = false;
      } else if (response.status === 'error') {
        this.toastr.error(response.message);
      }

      this.testingItemsLoading = false;
    });
  }

  private loadTestingDeviceList() {

    const page = new Page();

    page.pageNumber = 0;
    page.size = 100;
    page.sortOrder = 'ASC';

    this.electroDeviceTestingDeviceService.getList(page).subscribe(response => {

      const data = response.data;

      if (response.status === 'success') {
        this.testingDevices = [...data];
      }

      if (response.status === 'error') {
        this.toastr.error(response.message);
      }
    });
  }

  cancel(): void {
    this.done.emit(true);
    Swal.close();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  testItem(item: ElectroDeviceTestingItem, i: number, checkType: number) {

    if (this.testingItems.length === 0 || !this.canSelectTestResult(item, checkType)) {
      return;
    }

    // We set the selected testStatus
    item.testStatus = checkType;


    if (this.imageQueue[item.id] && this.imageQueue[item.id].length > 0) {
      const uploadObservables = this.imageQueue[item.id].map((fileItem: FileItem) =>
        this.electroDeviceTestingItemService.uploadImage(fileItem)
      );

      combineLatest(uploadObservables).subscribe(
        responses => {
          responses.forEach((response: DataResponse<any>) => {
            this.onAddImage(response.data);
          });

          this.processTestingItem(item, checkType); // Continue processing the item
        },
        error => {
          this.toastr.error('Die Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut!');
          console.error('Image upload error:', error);
        }
      );
    } else {
      // No images to upload, process the item directly
      this.processTestingItem(item, checkType);
    }

  }

  private processTestingItem(item: any, checkType: number): void {
    item.testingSending = true;
    this.loadingLastTestedItem = true;
    this.electroDeviceTestingItemService.checkDeviceTestingItem(this.testing, item).subscribe(
      response => {
        if (response.status === 'success') {
          const index = this.testingItems.indexOf(item);
          if (index !== -1) {
            this.testingItems.splice(index, 1);
            this.filteredRows = [...this.testingItems];
          }

          item.testStatus = checkType;
          this.populateLocationFilter();
          this.filterRows();

          this.lastTestedItem = response.data;
        } else if (response.status === 'error') {
          this.toastr.error(response.message);
        }

        this.getLatestItemWithHighestRegisterNumber();
        item.testingSending = false;
      },
      error => {
        this.toastr.error('An error occurred while testing the item.');
        console.error('Testing item error:', error);
        item.testingSending = false;
      }
    );
  }

  getTestStatusLabel(status: number): string {
    const type = HelperService.testingTypeList.find(item => item.id === status);
    return type ? type.name : 'Unbekannt';
  }

  isValidProtectionClass(protectionClass: number): boolean {
    return HelperService.protectionClassList.some(pc => pc.id === protectionClass);
  }

  canSelectTestResult(item: ElectroDeviceTestingItem, checkType: number): boolean {

    item.testingDeviceId = this.selectedTestingDeviceId;

    const commentRequired = checkType !== 1;
    let registerNumberRequired = this.registerNumberRequired;

    if (checkType === 2) {
      registerNumberRequired = false;
    }

    if (item.testingDeviceId === null) {
      this.toastr.error('Fehler: Prüfgerät ist nicht ausgewählt!');
      return;
    }

    // Check if there is a duplication for this id.
    if (this.filteredRows.some(row => row.parentItemId === item.id)) {
      this.toastr.error('Bitte prüfen Sie zuerst das duplizierte Gerät!');
      return;
    }

    if (!item.barcode || isNaN(Number(item.barcode)) || Number(item.barcode) < 1 || Number(item.barcode) > 999999) {
      this.toastr.error('Barcode ist nicht gültig! Barcode (1 - 999999)');
      return;
    }

    // check if barcode already exists in the testingItems
    if (item.isDuplicate) {
      if (this.testingItems.some((_item: ElectroDeviceTestingItem) => _item.barcode === item.barcode)) {
        this.toastr.error(`Barcode ${item.barcode} darf nicht benutzt werden, da es bereits in diesem Gebäude vorhanden ist.`);
        return;
      }
    }

    if (!item.location) {
      this.toastr.error('Fehler: Standort fehlt!');
      return;
    }

    if (!this.isValidProtectionClass(item.protectionClass)) {
      this.toastr.error('Fehler: Steuerklasse (SK) fehlt!');
      return;
    }

    if (item.nextTestingDateType === 0 && checkType !== 2) {
      this.toastr.error('Fehler: Nächste Prüfung muss ausgewählt werden!');
      return;
    }

    // 0 is a valid value here that is why we check with == for undefined and null
    if (item.nextTestingDateType == null) {
      this.toastr.error('Fehler: Nächste Prüfung ist nicht ausgewählt!');
      return;
    }

    if (registerNumberRequired && !item.registerNumber) {
      this.toastr.error('Fehler: Die Speichernummer muss eingetragen werden!');
      return;
    }

    if (commentRequired && !item.comment) {
      this.toastr.error('Fehler: Kommentar muss eingetragen werden!');
      return;
    }

    return true;
  }

  duplicate(item: any): void {

    if (this.findDuplicatesById(item.id)) {
      this.toastr.warning('Sie haben bereits ein Duplikat dieses Geräts erstellt');
      return;
    }

    // Find the index of the selected item
    const index = this.filteredRows.findIndex(row => row === item);

    if (index !== -1) {
      // Create a deep copy of the selected item
      const copiedItem = JSON.parse(JSON.stringify(item));

      // Modify the copied item if needed (e.g., assign a new ID)
      copiedItem.id = this.generateUniqueId(); // Replace with your logic to generate unique IDs
      copiedItem.barcode = '';
      copiedItem.isDuplicate = true;
      copiedItem.parentItemId = item.id;
      copiedItem.parentItemBarcode = item.barcode;

      // Insert the copied item right after the selected item
      this.filteredRows.splice(index + 1, 0, copiedItem);
    }
  }

  removeDuplicate(item: any): void {
    const index = this.filteredRows.findIndex(
      (row) => row.id === item.id
    );
    if (index !== -1) {
      this.filteredRows.splice(index, 1);
    }
  }

  findDuplicatesById(itemId: number): boolean {
    return this.filteredRows.filter(row => row.parentItemId === itemId).length > 0;
  }

// Utility function to generate a unique ID (example implementation)

  generateUniqueId(): number {
    return Date.now() + Math.floor(Math.random() * 1000); // Replace with your ID generation logic
  }

  onTestingDeviceChange(testingDevice: any) {
    this.loadingLastTestedItem = true;
    this.selectedTestingDeviceId = testingDevice.id;
    this.registerNumberRequired = testingDevice.isRegisterNumberRequired;

    this.getLatestTestedItem();
    this.getLatestItemWithHighestRegisterNumber();

  }

  getLatestItemWithHighestRegisterNumber() {
    this.loadingLastTestedItem = true;
    this.electroDeviceTestingItemService.getLatestItemWithHighestRegisterNumber(this.testing.id, this.selectedTestingDeviceId).subscribe(
      response => {
        if (response.status === 'success') {
          this.loadingLastTestedItem = false;
          this.lastRegisterNumber = response.data.registerNumber;
        } else if (response.status === 'error') {
          this.toastr.error(response.message);
        }
      }
    );
  }

  getLatestTestedItem() {
    this.electroDeviceTestingItemService.getLastTestedItem(this.testing.id, this.selectedTestingDeviceId).subscribe(
      response => {
        if (response.status === 'success') {
          this.loadingLastTestedItem = false;
          this.lastTestedItem = response.data;
        } else if (response.status === 'error') {
          this.toastr.error(response.message);
        }
      }
    );
  }

  onAddImage(data: any): void {
    const fileData = {name: data.fileName};
    this.selectedTestingItem.images.push(fileData);
  }

  removeImage(fileItem: FileItem, item: ElectroDeviceTestingItem) {
    this.imageQueue[item.id] = this.imageQueue[item.id].filter((_fileItem: FileItem) => _fileItem !== fileItem);
  }

  trackById(index: number, item: any): number {
    return item.id;
  }

  onNumericInput(event: Event, limit = 6): void {
    const inputElement = event.target as HTMLInputElement;

    // Keep only numeric characters and limit to 6 digits
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '').slice(0, limit);
  }

  refresh() {
    this.loadTestingItems();
  }

  displayDeviceCountByLocationList() {

    this.isDeviceListView = false;
    this.locationDeviceCountLoading = true;
    this.locationCountList = Object.entries(
      this.testingItems.reduce((acc, item) => {
        const location = item.location; // Assuming location is a property of ElectroDeviceTestingItem
        acc[location] = (acc[location] || 0) + 1; // Count occurrences of each location
        return acc;
      }, {})
    ).map(([location, count]) => ({
      location,
      deviceCount: count
    }));
    this.locationDeviceCountLoading = false;
  }

  displayDeviceList() {
    this.isDeviceListView = true;
    this.locationCountList = [];
  }

  sortBy(column: string): void {
    if (this.sortColumn === column) {
      // Toggle sorting direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new sorting column and default to ascending order
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    // Perform the sorting
    this.locationCountList.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }
}
